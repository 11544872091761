import { Dialog } from "primereact/dialog";
import React from "react";
import { COLORSCHEMEA } from "../../../../../ColorSchema/ColorSchema";
import { Button } from "primereact/button";
import { apiCall } from "../../../../../Backend/Backend";
import {
  BoldText,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
} from "../../../Overview/Styled";
import NumberFormatIn from "../../../../Common/NumberFormatIn";

export default function LiveSummaryModal({
  visible,
  position,
  setVisible,
  clientUsername,
  usersId,
  weekly_pl,
  credit,
  blocked_limit,
}) {
  const [loading, setLoading] = React.useState(true);
  const [activeSummary, setActiveSummary] = React.useState("");
  const [totalPL, setTotalPL] = React.useState("");

  React.useEffect(() => {
    load_users_active_summary();
  }, [usersId]);

  const load_users_active_summary = async () => {
    let sendData = {
      c_id: usersId,
    };
    console.log(sendData);
    const response = await apiCall("/load_users_active_summary", sendData);
    let PL = parseFloat(weekly_pl) + parseFloat(response.data);
    setTotalPL(parseFloat(PL).toFixed(0));
    setActiveSummary(response.data);
    setLoading(false);
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: COLORSCHEMEA.THEMEFONT }}
      />
      {/* <Button
        label="PDF"
        icon="pi pi-download"
        onClick={() => alert("PDF")}
        autoFocus
        style={{ backgroundColor: COLORSCHEMEA.MainColor }}
      /> */}
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: COLORSCHEMEA.WHITECOLOR }}>
          {"  Client Live Summary : "}
          {clientUsername && clientUsername}
        </div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={visible}
      position={position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div style={{ marginTop: 20 }}>
        <FlexContainer>
          <FlexItem>
            <GrayText>Booked P/L</GrayText>
            <GreenText
              style={{
                color:
                  weekly_pl >= 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR,
              }}
            >
              <NumberFormatIn value={parseFloat(weekly_pl).toFixed(0)} />
            </GreenText>
          </FlexItem>
          <FlexItem>
            <GrayText>Live P/L</GrayText>
            <GreenText
              style={{
                color:
                  activeSummary != null && parseFloat(activeSummary) > 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR,
              }}
            >
              <NumberFormatIn
                value={
                  activeSummary == null
                    ? 0
                    : parseFloat(activeSummary).toFixed(0)
                }
              />
            </GreenText>
          </FlexItem>
          <FlexItem>
            <GrayText>Total P/L</GrayText>
            <GreenText
              style={{
                color:
                  totalPL != null && parseFloat(totalPL) > 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR,
              }}
            >
              <NumberFormatIn
                value={
                  totalPL == null || isNaN(totalPL)
                    ? 0
                    : parseFloat(totalPL).toFixed(0)
                }
              />
            </GreenText>
          </FlexItem>
        </FlexContainer>

        <FlexContainer>
          <FlexItem>
            <GrayText>Margin</GrayText>
            <BoldText>
              <NumberFormatIn value={parseFloat(credit).toFixed(0)} />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Blocked Margin</GrayText>
            <BoldText>
              <NumberFormatIn value={parseFloat(blocked_limit).toFixed(0)} />
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Free Margin</GrayText>
            <BoldText>
              <NumberFormatIn
                value={parseFloat(
                  parseFloat(credit) - parseFloat(blocked_limit)
                ).toFixed(0)}
              />
            </BoldText>
          </FlexItem>
        </FlexContainer>
      </div>
    </Dialog>
  );
}

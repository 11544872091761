import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { ToWords } from "to-words";

import { useNotification } from "../../../../Notification/NotificationContext";
import { FlexItem } from "../../../Overview/Styled";
import { BalanceInput, BalanceLabel } from "../../../Add/AddUser";
import { apiCall } from "../../../../../Backend/Backend";
import { COLORSCHEMEA } from "../../../../../ColorSchema/ColorSchema";
import NumberFormatIn from "../../../../Common/NumberFormatIn";

export default function BalanceModal({
  visible,
  position,
  setVisible,
  clientUsername,
  usersId,
  reload,
  deposit,
}) {
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [balance, setBalance] = React.useState("");

  const balanceAdd = async () => {
    if (
      isNaN(parseFloat(balance)) ||
      parseFloat(balance) == null ||
      parseFloat(balance) == "" ||
      parseFloat(balance) == undefined
    ) {
      addNotification("Enter Number Greater Than 0 ", "error");
    } else {
      if (!loading) {
        setLoading(true);
        let sendData = {
          c_id: usersId,
          balance: parseFloat(balance).toFixed(3),
        };

        const response = await apiCall("/add_users_balance", sendData);
        if (response.error == "False") {
          setVisible();
          addNotification(response.message, "success");
        } else {
          addNotification(response.message, "error");
        }
        setLoading(false);
        reload();
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="danger"
        onClick={() => setVisible(false)}
      />
      <Button
        label="Add "
        icon="pi pi-plus"
        onClick={() => balanceAdd()}
        style={{ backgroundColor: COLORSCHEMEA.THEMEFONT }}
      />
    </div>
  );

  return (
    <Dialog
      header={
        <div style={{ color: COLORSCHEMEA.WHITECOLOR }}>
          {"  Add Balance : "}
          {clientUsername && clientUsername}
        </div>
      }
      headerStyle={{
        backgroundColor: COLORSCHEMEA.THEMEFONT,
      }}
      visible={visible}
      position={position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BalanceLabel>
          {"Balance ( "}
          <span
            style={{
              color:
                deposit >= 0
                  ? COLORSCHEMEA.GREENCOLOREXCEL
                  : COLORSCHEMEA.REDCOLOREXCEL,
            }}
          >
            <NumberFormatIn value={parseFloat(deposit)} />{" "}
          </span>
          {" )"}
        </BalanceLabel>
      </FlexItem>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BalanceInput
          type="number"
          value={balance}
          onChange={(e) => setBalance(e.target.value)}
          placeholder="Enter Amount In +/-"
          style={{
            width: "100%",
          }}
          autoFocus
        />
        <div
          style={{
            fontWeight: "bold",
            marginBottom: 5,
            paddingLeft: 20,
            color: "gray",
          }}
        >
          {balance != "" ? toWords.convert(balance) : ""}
        </div>
      </div>
    </Dialog>
  );
}

import React from "react";
import TopbarMost from "../../Common/TopbarMost";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNotification } from "../../Notification/NotificationContext";
import { apiCall } from "../../../Backend/Backend";
import { FilterMatchMode } from "primereact/api";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import { NewTheme } from "../../Theme/Theme";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as ExcelJS from "exceljs";

const height = window.innerHeight;
export default function SummaryNew({ selectedClient }) {
  const [loading, setLoading] = React.useState(false);
  const [summary, setSummary] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_users_summary();
  }, []);

  const load_users_summary = async () => {
    let sendData = {
      c_id: selectedClient.id,
    };

    const response = await apiCall("/load_users_summary", sendData);
    setLoading(false);
    setSummary(response.users_summary);
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = ["name", "username", "pl", "deposit", "brokers"];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Client",
      "Username",
      "Profit/Loss",
      "Deposit",
      "Broker",
    ];

    const headerRow = worksheet
      .addRow([
        "CLIENTS SUMMARY (S)" +
          localStorage.getItem("server").toUpperCase() +
          " (M)" +
          localStorage.getItem("mastersUsername")?.toUpperCase(),
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:E${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    const headerDisplayNamesRow = worksheet.addRow(headerDisplayNames);

    // Make the header row bold
    headerDisplayNamesRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Extract only the desired columns from transactionData
    const filteredData = summary.map((item) => {
      return colsToExport.reduce((acc, col) => {
        acc[col] = item[col];
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    // Apply color to font based on the "profit_loss" condition
    const colIndex = colsToExport.indexOf("pl");
    const depositIndex = colsToExport.indexOf("deposit");
    const usernameIndex = colsToExport.indexOf("username"); // Add this line

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const plValue = row.getCell(colIndex + 1).value; // "pl" column value
      const depositValue = row.getCell(depositIndex + 1).value; // "deposit" column value
      const usernameValue = row.getCell(usernameIndex + 1).value; // "username" column value

      // Format for "profit_loss" column
      if (plValue !== null && rowNumber > 4) {
        const plColor =
          plValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(colIndex + 1).font = {
          color: { argb: plColor },
          bold: true, // Make the font bold
        };

        // Convert "profit_loss" to number and set number format
        row.getCell(colIndex + 1).value =
          rowNumber > 4 ? Number(plValue) : plValue;
        row.getCell(colIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "deposit" column
      if (depositValue !== null && rowNumber > 4) {
        const depositColor =
          depositValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(depositIndex + 1).font = {
          color: { argb: depositColor },
          bold: true, // Make the font bold
        };

        // Convert "deposit" to number and set number format
        row.getCell(depositIndex + 1).value =
          rowNumber > 4 ? Number(depositValue) : depositValue;
        row.getCell(depositIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "username" column
      if (usernameValue !== null && rowNumber > 4) {
        row.getCell(usernameIndex + 1).font = {
          color: { argb: NewTheme.MainColorExcel }, // Set the font color for the "username" column
          bold: true, // Make the font bold
        };
      }
    });

    var fileName =
      "CLIENTS SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (M)" +
      localStorage.getItem("mastersUsername")?.toUpperCase();

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const cols = [
    { dataKey: "name", title: "Name" },
    { dataKey: "username", title: "Username" },
    { dataKey: "pl", title: "Profit/Loss" },
    { dataKey: "deposit", title: "Deposit" },
    { dataKey: "brokers", title: "Brokers" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("CLIENTS SUMMARY", 15, 15);

    doc.setFontSize(12);
    doc.text(`Server: ${localStorage.getItem("server").toUpperCase()}`, 15, 25);

    doc.setFontSize(12);
    doc.text(
      `Master: ${localStorage.getItem("mastersUsername").toUpperCase()}`,
      55,
      25
    );

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 35; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, summary, { startY: tableStartY, headerStyles });

    var pdfName =
      "CLIENTS SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (M)" +
      localStorage.getItem("mastersUsername")?.toUpperCase();

    doc.save(pdfName + ".pdf");
  };

  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filters["global"] ? filters["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
          borderColor: "lightgray",
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "left",
            paddingLeft: 10,
            fontWeight: "bold",
          }}
        >
          CLIENT SUMMARY
        </div>
        <div style={{ width: 300 }}>
          <InputText
            placeholder="SEARCH CLIENTS"
            style={{ width: "90%", height: 30 }}
          />
        </div>
        <div style={{ width: 50 }}>
          <Button
            type="button"
            label="XLS"
            style={{
              height: 25,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
              color: NewTheme.greencolor,
              backgroundColor: "#fff",
              borderColor: NewTheme.greencolor,
            }}
            onClick={exportExcel}
            data-pr-tooltip="EXCEL"
          />
        </div>
        <div style={{ width: 50 }}>
          <Button
            type="button"
            label="PDF"
            style={{
              height: 25,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
              color: NewTheme.orangecolor,
              backgroundColor: "#fff",
              borderColor: NewTheme.orangecolor,
            }}
            onClick={exportPdf}
            data-pr-tooltip="PDF"
          />
        </div>
      </div>
      <DataTable
        removableSort
        stripedRows
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        showGridlines
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        scrollHeight={height - 200}
        scrollable
        paginator
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        value={summary}
        loading={loading}
        style={{ fontSize: 15 }}
        size="small"
      >
        <Column
          style={{ width: "15%" }}
          showFilterMenu={false}
          field="name"
          header="Name"
          sortable
          filter
        />
        <Column
          style={{ width: "15%" }}
          showFilterMenu={false}
          field="username"
          header="Username"
          filter
          sortable
        />

        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="deposit"
          header="Balance"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.deposit >= 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR,
              }}
            >
              <NumberFormatIn value={parseFloat(rowData.deposit).toFixed(0)} />
            </span>
          )}
        />

        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="credit"
          header="Credit"
          body={(rowData) => (
            <span>
              <NumberFormatIn value={rowData.credit} />
            </span>
          )}
        />
        <Column
          style={{
            width: "20%",
          }}
          sortable
          filter
          showFilterMenu={false}
          field="pl"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.pl >= 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR,
              }}
            >
              <NumberFormatIn value={rowData.pl} />
            </span>
          )}
          header="Profit/Loss"
        />

        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="rent"
          header="Rent"
          body={(rowData) => (
            <span>
              <NumberFormatIn value={rowData.rent} />
            </span>
          )}
        />

        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="brokers"
          header="Broker"
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="brokerage_return"
          header="Brokerage"
        />
      </DataTable>
    </div>
  );
}
